import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthUserService} from "../../@core/services/auth/auth-user.service";
import {Router} from "@angular/router";
import * as AuthActions from './auth.actions';
import {Injectable} from "@angular/core";
import {catchError, map, of, switchMap} from "rxjs";
import {logout} from "./auth.actions";
import {Store} from "@ngrx/store";
import {AuthService} from "@auth0/auth0-angular";

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authUser: AuthUserService, private router: Router, private store: Store, private authService: AuthService) {
  }

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.getUserFromToken),
    switchMap(() => this.authUser.getMe()
      .pipe(
        map(user => AuthActions.setUser({ user })),
        catchError(error => {
          this.store.dispatch(logout());
          return of(AuthActions.userFailure({ error: error.error }))
        })
      )
    )
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map(() => {
      this.authService.logout({logoutParams: {returnTo: window.location.origin}});
      return AuthActions.logoutSuccess();
    })
  ));
}
