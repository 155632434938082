import {IUser} from "../../@core/services/auth/auth-user.interface";
import {IApiBody} from "../../@core/interfaces/IApi";
import * as AuthActions from './auth.actions';
import {createReducer, on} from "@ngrx/store";

export interface AuthState {
  user: IUser | null | undefined
  error: IApiBody<any> | null;
}

export const initialState: AuthState = {
  user: null,
  error: null
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.setUser, (state, { user }) => ({ ...state, user, error: null })),
  on(AuthActions.userFailure, (state, { error }) => ({ ...state, error, user: null })),
  on(AuthActions.logoutSuccess, state => ({ ...state, user: null, error: null })),
  // snippet per far finta di essere un altro ruolo , ricorda di commentare setUser sopra
  /* on(AuthActions.setUser, (state, { user }) => {
    user.role = ERole.OPERATOR;

    return { ...state, user, error: null };
  }), */
)
