@if (loading){
  <div class="vh-100 d-flex align-items-center justify-content-center">
    <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 578.9 122.51" width="400">
      <defs>
        <style>
          .cls-1 {
            fill: url(#Sfumatura_senza_nome_3);
          }

          .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
            stroke-width: 0px;
          }

          .cls-2 {
            fill: #7014f2;
          }

          .cls-3 {
            fill: #00f59b;
          }

          .cls-4 {
            fill: url(#Sfumatura_senza_nome_3-2);
          }

          .cls-5 {
            fill: url(#Sfumatura_senza_nome_3-3);
          }
        </style>
        <linearGradient id="Sfumatura_senza_nome_3" data-name="Sfumatura senza nome 3" x1="0" y1="112.52" x2="80.33" y2="112.52" gradientTransform="translate(0 124) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#00f59b"></stop>
          <stop offset="1" stop-color="#7014f2"></stop>
        </linearGradient>
        <linearGradient id="Sfumatura_senza_nome_3-2" data-name="Sfumatura senza nome 3" x1="53.97" y1="65.28" x2="80.75" y2="65.28" xlink:href="#Sfumatura_senza_nome_3"></linearGradient>
        <linearGradient id="Sfumatura_senza_nome_3-3" data-name="Sfumatura senza nome 3" x1="27.19" y1="88.9" x2="80.74" y2="88.9" xlink:href="#Sfumatura_senza_nome_3"></linearGradient>
      </defs>
      <g id="Livello_2-2" data-name="Livello 2">
        <g>
          <g id="Livello_2-2" data-name="Livello 2-2">
            <path class="cls-2 svg-elem-1" d="m88.68,1.7h19.69v54.82c0,11.3,6.2,17.5,17.26,17.5s17.5-6.44,17.5-17.5V1.7h19.69v56.16c0,21.39-14.59,35.25-37.19,35.25s-36.95-13.74-36.95-35.25c0,0,0-56.16,0-56.16Z"></path>
            <path class="cls-2 svg-elem-2" d="m178.62,91.65V0h18.72v91.65h-18.72Z"></path>
            <path class="cls-2 svg-elem-3" d="m217.28,91.65v-45.22h-11.55v-15.56h11.55V12.03h18.72v18.84h11.55v15.56h-11.55v45.22h-18.72Z"></path>
            <path class="cls-2 svg-elem-4" d="m297.74,48.5h-5.96c-10.7,0-17.14,4.74-17.14,16.77v26.38h-18.72V31h17.62l.97,8.99c2.55-5.96,7.9-9.97,16.04-9.97,2.19,0,4.62.24,7.17.85v17.62h.02Z"></path>
            <path class="cls-2 svg-elem-5" d="m302.24,73.78c0-11.18,7.9-18.11,22.49-19.2l17.02-1.34v-.97c0-5.96-3.65-9.12-10.33-9.12-7.9,0-12.15,3.04-12.15,8.51h-15.56c0-13.74,11.3-22.73,28.69-22.73s27.59,9.85,27.59,27.23v35.49h-16.53l-1.22-8.02c-1.94,5.59-9.97,9.6-19.08,9.6-12.88,0-20.91-7.54-20.91-19.45h-.01Zm39.63-4.86v-3.28l-9.48.85c-8.14.73-11.06,2.55-11.06,6.44,0,4.38,2.67,6.44,8.39,6.44,7.17,0,12.16-3.52,12.16-10.45h-.01Z"></path>
            <path class="cls-3 svg-elem-6" d="m373.35,30.87h17.62l.97,7.41c3.4-5.59,11.06-9.36,19.69-9.36,16.9,0,28.69,11.79,28.69,31.36s-10.45,33.06-28.56,33.06c-8.39,0-16.17-3.16-19.69-7.78v35.25h-18.72V30.87Zm33.67,45.58c8.87,0,14.46-6.2,14.46-15.19s-5.59-15.19-14.46-15.19-14.83,6.08-14.83,15.19,6.08,15.19,14.83,15.19Z"></path>
            <path class="cls-3 svg-elem-7" d="m448.59,73.78c0-11.18,7.9-18.11,22.49-19.2l17.02-1.34v-.97c0-5.96-3.65-9.12-10.33-9.12-7.9,0-12.15,3.04-12.15,8.51h-15.56c0-13.74,11.3-22.73,28.69-22.73s27.59,9.85,27.59,27.23v35.49h-16.53l-1.22-8.02c-1.94,5.59-9.97,9.6-19.08,9.6-12.88,0-20.91-7.54-20.91-19.45h-.01Zm39.62-4.86v-3.28l-9.48.85c-8.14.73-11.06,2.55-11.06,6.44,0,4.38,2.67,6.44,8.39,6.44,7.17,0,12.16-3.52,12.16-10.45h-.01Z"></path>
            <path class="cls-3 svg-elem-8" d="m513.01,105.14h10.33c6.32,0,8.99-1.94,11.43-8.51l1.09-2.92-24.43-62.84h20.06l13.25,39.02,14.71-39.02h19.45l-30.87,74.02c-5.1,12.4-11.91,17.62-22.61,17.62-4.5,0-8.75-.61-12.4-1.7v-15.68h-.01Z"></path>
          </g>
          <g id="Livello_3" data-name="Livello 3">
            <path class="cls-1 svg-elem-9" d="m9.82,1.66h70.51v19.64H9.82C4.4,21.3,0,16.9,0,11.48h0C0,6.06,4.4,1.66,9.82,1.66Z"></path>
            <path class="cls-4 svg-elem-10" d="m63.79,48.9h16.96v19.64h-16.96c-5.42,0-9.82-4.4-9.82-9.82h0c0-5.42,4.4-9.82,9.82-9.82Z"></path>
            <path class="cls-5 svg-elem-11" d="m37.01,25.28h43.73v19.64h-43.73c-5.42,0-9.82-4.4-9.82-9.82h0c0-5.42,4.4-9.82,9.82-9.82Z"></path>
          </g>
        </g>
      </g>
    </svg>
  </div>`
} @else {
  <router-outlet/>
}

