import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from "./@core/core.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from "../environments/environment";
import {authReducer} from "./store/auth/auth.reducer";
import {AuthEffects} from "./store/auth/auth.effects";
import {CountUpModule} from "ngx-countup";
import {dashboardReducer} from './store/dashboard/dashboard.reducer';
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import { ErrorInterceptor } from './@core/interceptors/error.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    CountUpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      auth: authReducer, dashboard: dashboardReducer
    }, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !environment.production, // Restrict extension to log-only mode
    }),
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        // Enabliong theme combination
        extendsFromRoot: true,
        // ... mid-list of CSS theme attributes
        height: '35px',
        'border-radius': '5px',
        margin: 0,
      },
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
      },
      httpInterceptor: {

        allowedList: [
          {
            // uri: `${environment.url_api}/*`,
            uriMatcher: (uri: string): boolean => {
              if(uri.includes(`${environment.url_api}/auth/registration`) && !uri.startsWith(`${environment.url_api}/auth/registration/send`)) return false
              return uri.startsWith(`${environment.url_api}/`)
            },
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0.audience,
              }
            }
          }

        ]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
