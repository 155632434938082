import { FilterButtonValue } from '../../@shared/enum/filter-button.enum';
import { EPageReference, TabType } from '../../@shared/enum/tab-type.enum';
import { IPagination } from '../../@shared/interfaces/dashboard.interface';
import { IDateRangeUnix } from '../../@shared/interfaces/filter-button-group.interface';
import * as dashboardActions from './dashboard.actions';
import {createReducer, on} from "@ngrx/store";

export interface DashboardState {
  formFilters: any;
  pagination: IPagination | undefined;
  activeRadio: FilterButtonValue | undefined;
  dateRange: IDateRangeUnix | undefined;
  docId: string | undefined;
  tabType: TabType | undefined;
  pageReference: TabType | EPageReference | undefined;
  silentUpdate: boolean;
}

export const initialState: DashboardState = {
  formFilters: null,
  pagination: undefined,
  activeRadio: undefined,
  dateRange: undefined,
  docId: undefined,
  tabType: undefined,
  pageReference: undefined,
  silentUpdate: false,
};

export const dashboardReducer = createReducer(
  initialState,
  on(dashboardActions.filtersChoicesActions.setFiltersChoices, (state, { formFilters }) => ({
    ...state,
    formFilters,
    pagination: {
      ...state.pagination!,
      page: 1
    },
    docId: undefined,
    silentUpdate: false 
  })),
  on(dashboardActions.filtersChoicesActions.resetFilters , (state) => ({ ...state, formFilters: null, pagination: {page: 1, size: 10}, silentUpdate: false })),
  on(dashboardActions.filtersChoicesActions.resetPagination , (state) => ({ ...state, pagination: {page: 1, size: 10}, silentUpdate: false })),
  on(dashboardActions.filtersChoicesActions.setPagination , (state, { pagination }) => ({
     ...state,
     pagination,
     docId: undefined,
     silentUpdate: false 
    })),
    on(dashboardActions.filtersChoicesActions.setActiveRadio , (state, { activeRadio, dateRange }) => ({ 
      ...state,
      dateRange,
      activeRadio,
     pagination: { ...state.pagination!, page: 1},
     silentUpdate: false 
     })),
  on(dashboardActions.filtersChoicesActions.setDateRange , (state, { dateRange }) => ({
    ...state,
    dateRange,
    silentUpdate: false 
  })),
  on(dashboardActions.filtersChoicesActions.setDocId , (state, { docId }) => ({ ...state, docId, silentUpdate: true })),
  on(dashboardActions.filtersChoicesActions.setTabType , (state, { tabType }) => ({ ...state, tabType , pageReference: tabType, silentUpdate: false })),
)
