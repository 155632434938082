import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "@auth0/auth0-angular";
import {Router} from "@angular/router";
import {ModalService} from "./@core/services/modal.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  loading = true;
  isError = false;

  constructor(public translate: TranslateService, private authService: AuthService, private router: Router, private modalService: ModalService, private title: Title) {
    this.title.setTitle('Ultrapay')
    this.authService.error$.subscribe((err) => {
      this.isError = true;
      this.modalService.openModalText('User info', err.message, 'error', 'md', 'center', true, 'retry login').subscribe(res => {
        this.authService.loginWithRedirect()
      })
    })


    this.authService.isAuthenticated$.subscribe((res) => {
      setTimeout(() => {
        this.loading = false;
        if (!res) {
          if (this.router.url.includes('complete-registration')) {
            return;
          }
          if (!this.isError) {
            this.authService.loginWithRedirect()
          }
        } else if (!this.router.url.includes('dashboard')) {
          this.router.navigate(['/dashboard'])
        }
      }, 1000)
    })

    translate.addLangs(['it', 'en']);
    translate.setDefaultLang('it');
  }
}
