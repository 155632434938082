import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {authGuard} from "./@core/guards/auth.guard";

const routes: Routes = [
  {path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [authGuard]},
  {path: 'complete-registration', loadChildren: () => import('./features/complete-registration/complete-registration.module').then(m => m.CompleteRegistrationModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
