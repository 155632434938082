import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../@shared/alert/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public translate: TranslateService, private alertService: AlertService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = error?.error?.message ? error.error.message : 'Unknown Error';
        this.alertService.openErrorAlert(errorMessage)
        return throwError(() => error.error);
      })
    );
  }
}
